import React, { useState, useEffect } from 'react';
import { Container, Grid, Button, Typography, Grow, Box } from '@mui/material';
import axios from 'axios';

function HomePage() {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login state
  const [isPatron, setIsPatron] = useState(false); // Track Patreon membership state
  const [loading, setLoading] = useState(true); // Track loading state

  // Check login and Patreon membership status on page load
  useEffect(() => {
    axios
      .get('https://api.htdapp.com/api/auth/status', { withCredentials: true })
      .then(response => {
        if (response.data.isAuthenticated) {
          setIsLoggedIn(true);
          localStorage.setItem('isAuthenticated', 'true');

          // Check Patreon membership
          axios.get('https://api.htdapp.com/api/check_patreon_membership', { withCredentials: true })
            .then(patreonResponse => {
              if (patreonResponse.data.isPatron) {
                setIsPatron(true);
                localStorage.setItem('isPatron', 'true');
              } else {
                setIsPatron(false);
                localStorage.removeItem('isPatron');
              }
              setLoading(false);
            })
            .catch(error => {
              console.error('Error checking Patreon membership:', error);
              setLoading(false);
            });
        } else {
          setIsLoggedIn(false);
          setIsPatron(false);
          localStorage.removeItem('isAuthenticated');
          localStorage.removeItem('isPatron');
          setLoading(false);
        }
      })
      .catch(error => {
        console.error('Error fetching login status:', error);
        setLoading(false);
      });
  }, []);


  if (loading) {
    return <p>Loading...</p>;  // Loading state while checking login and Patreon membership status
  }

  return (
    <>
      <Grow in timeout={1000}> 
        <Container> 
          <Grid container justifyContent="center" alignItems="center" spacing={2} style={{ height: '100vh' }}>
            <Grid item xs={12}>
              <Typography variant="h1" align="center">
                Welcome to How to Drink!
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Button component="a" href="/drinks" variant="contained" color="primary" fullWidth>
                Go to Drinks
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button component="a" href="/episodes" variant="contained" color="secondary" fullWidth>
                Go to Episodes
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Grow>

      {/* Blurry background with login prompt if not logged in */}
      {!isLoggedIn && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backdropFilter: 'blur(10px)', // Blurry effect
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark translucent background
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              padding: '2rem',
              backgroundColor: '#404040',
              borderRadius: '16px',
              boxShadow: '0 8px 16px rgba(0,0,0,0.3)',
              textAlign: 'center',
            }}
          >
            <Typography variant="h5" gutterBottom>
              Please login to continue
            </Typography>
            <Button href="https://api.htdapp.com/login/patreon/" variant="contained" color="success">
              Login with Patreon
            </Button>
          </Box>
        </Box>
      )}

      {/* Check Patreon membership */}
      {isLoggedIn && !isPatron && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              padding: '2rem',
              backgroundColor: '#404040',
              borderRadius: '16px',
              boxShadow: '0 8px 16px rgba(0,0,0,0.3)',
              textAlign: 'center',
            }}
          >
            <Typography variant="h5" gutterBottom>
              You need to be an active patron to access this content.
            </Typography>
            <Button href="https://www.patreon.com/" variant="contained" color="success">
              Become a Patron
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}

export default HomePage;
