import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  TextField,
  ToggleButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Drinks() {
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';
  const [drinks, setDrinks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedDescriptors, setSelectedDescriptors] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]); // New state for ingredients
  const [selectedMedia, setSelectedMedia] = useState('');
  const [isCursed, setIsCursed] = useState(false);

  const [availableDescriptors, setAvailableDescriptors] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);
  const [availableIngredients, setAvailableIngredients] = useState([]); // Available ingredients
  const [availableMedia, setAvailableMedia] = useState([]);

  // Fetch drinks data
  useEffect(() => {
    fetch(`${API_URL}/api/drinks/`, { credentials: 'include' })
      .then((response) => response.json())
      .then((data) => {
        setDrinks(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching drinks:', error);
        setLoading(false);
      });
  }, [API_URL]);

  // Fetch filter options dynamically
  useEffect(() => {
    fetch(`${API_URL}/api/descriptor-options/`, { credentials: 'include' })
      .then((response) => response.json())
      .then((data) => setAvailableDescriptors(data))
      .catch((error) => console.error('Error fetching descriptors:', error));

    fetch(`${API_URL}/api/tag-options/`, { credentials: 'include' })
      .then((response) => response.json())
      .then((data) => setAvailableTags(data))
      .catch((error) => console.error('Error fetching tags:', error));

    fetch(`${API_URL}/api/ingredient-options/`, { credentials: 'include' }) // Fetch ingredients
      .then((response) => response.json())
      .then((data) => setAvailableIngredients(data))
      .catch((error) => console.error('Error fetching ingredients:', error));

    fetch(`${API_URL}/api/media-options/`, { credentials: 'include' })
      .then((response) => response.json())
      .then((data) => setAvailableMedia(data))
      .catch((error) => console.error('Error fetching media:', error));
  }, [API_URL]);

  const handleToggleChange = (selectedArray, setSelectedArray, value) => {
    if (selectedArray.includes(value)) {
      setSelectedArray(selectedArray.filter((item) => item !== value));
    } else {
      setSelectedArray([...selectedArray, value]);
    }
  };

  const handleChipDelete = (setSelectedArray, value) => {
    setSelectedArray((prevSelectedArray) => prevSelectedArray.filter((item) => item !== value));
  };

  const handleClearAll = () => {
    setSelectedTypes([]);
    setSelectedCategories([]);
    setSelectedDescriptors([]);
    setSelectedTags([]);
    setSelectedIngredients([]); // Clear ingredients
    setSelectedMedia('');
    setIsCursed(false);
  };

  const filteredDrinks = drinks.filter((drink) => {
    const matchesType = selectedTypes.length === 0 || selectedTypes.includes(drink.type_of_drink);
    const matchesCategory = selectedCategories.length === 0 || selectedCategories.includes(drink.category);
    const matchesDescriptor = selectedDescriptors.length === 0 || selectedDescriptors.includes(drink.descriptor);
    const matchesTags = selectedTags.length === 0 || drink.tags.some((tag) => selectedTags.includes(tag.name));
    const matchesIngredients = selectedIngredients.length === 0 || drink.ingredients.some((ingredient) =>
      selectedIngredients.includes(ingredient.name)
    );
    const matchesMedia =
      selectedMedia === '' || drink.media_adapted_from.some((media) => media.title === selectedMedia);
    const matchesCursed = !isCursed || drink.cursed === true;

    return (
      drink.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      matchesType &&
      matchesCategory &&
      matchesDescriptor &&
      matchesTags &&
      matchesIngredients && // Filter by ingredients
      matchesMedia &&
      matchesCursed
    );
  });

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container>
      <Box my={3}>
        <Typography variant="h3" align="center" gutterBottom>
          Drinks
        </Typography>
      </Box>

      {/* Search Input */}
      <TextField
        label="Search drinks..."
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        margin="normal"
      />

      {/* Accordion Filters */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Category</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {['classic', 'modern_classic', 'avant_garde', 'tiki', '70s_style'].map((category) => (
            <ToggleButton
              key={category}
              selected={selectedCategories.includes(category)}
              onChange={() => handleToggleChange(selectedCategories, setSelectedCategories, category)}
              value={category}
              style={{ margin: '5px', minWidth: '100px' }} // Adjust button size
            >
              {category}
            </ToggleButton>
          ))}
        </AccordionDetails>
      </Accordion>

      {/* Ingredients Accordion */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Ingredients</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {availableIngredients.map((ingredient) => (
            <ToggleButton
              key={ingredient}
              selected={selectedIngredients.includes(ingredient)}
              onChange={() => handleToggleChange(selectedIngredients, setSelectedIngredients, ingredient)}
              value={ingredient}
              style={{ margin: '5px', minWidth: '100px' }}  // Adjust button size
            >
              {ingredient}
            </ToggleButton>
          ))}
        </AccordionDetails>
      </Accordion>

      {/* Other Accordions (Tags, Descriptor, Media) */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Tags</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {availableTags.map((tag) => (
            <ToggleButton
              key={tag}
              selected={selectedTags.includes(tag)}
              onChange={() => handleToggleChange(selectedTags, setSelectedTags, tag)}
              value={tag}
              style={{ margin: '5px', minWidth: '100px' }}  // Adjust button size
            >
              {tag}
            </ToggleButton>
          ))}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Descriptor</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {availableDescriptors.map((descriptor) => (
            <ToggleButton
              key={descriptor}
              selected={selectedDescriptors.includes(descriptor)}
              onChange={() => handleToggleChange(selectedDescriptors, setSelectedDescriptors, descriptor)}
              value={descriptor}
              style={{ margin: '5px', minWidth: '100px' }}  // Adjust button size
            >
              {descriptor}
            </ToggleButton>
          ))}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Media Adapted From</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl fullWidth>
            <InputLabel>Media Adapted From</InputLabel>
            <Select value={selectedMedia} onChange={(e) => setSelectedMedia(e.target.value)}>
              <MenuItem value="">All Media</MenuItem>
              {availableMedia.map((media) => (
                <MenuItem key={media} value={media}>
                  {media}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </AccordionDetails>
      </Accordion>

      {/* Selected Filters Chips */}
      <Box mt={2} mb={2}>
        {selectedCategories.map((category) => (
          <Chip
            key={category}
            label={category}
            onDelete={() => handleChipDelete(setSelectedCategories, category)}
          />
        ))}
        {selectedTags.map((tag) => (
          <Chip
            key={tag}
            label={tag}
            onDelete={() => handleChipDelete(setSelectedTags, tag)}
          />
        ))}
        {selectedIngredients.map((ingredient) => (
          <Chip
            key={ingredient}
            label={ingredient}
            onDelete={() => handleChipDelete(setSelectedIngredients, ingredient)}
          />
        ))}
        <Button onClick={handleClearAll}>Clear All</Button>
      </Box>

      {/* Filtered Drinks List */}
      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        {filteredDrinks.length > 0 ? (
          filteredDrinks.map((drink) => (
            <Grid item xs={6} sm={3} md={2} key={drink.id}>
              <Link to={`/drinks/${drink.id}`} style={{ textDecoration: 'none' }}>
                <Card
                  style={{
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px',
                    transition: 'box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out',
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = 'scale(1.05)';
                    e.currentTarget.style.boxShadow = '0px 8px 12px rgba(0, 0, 0, 0.2)';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = 'scale(1)';
                    e.currentTarget.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)';
                  }}
                >
                  <CardContent style={{ textAlign: 'center' }}>
                    <Typography variant="h5" align="center">
                      {drink.name}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="h6">No drinks found</Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default Drinks;
