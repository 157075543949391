import { createTheme } from '@mui/material/styles';

// Import the font files
import EvelethCleanRegularWoff2 from './assets/fonts/EvelethCleanRegular.woff2';
import EvelethCleanRegularWoff from './assets/fonts/EvelethCleanRegular.woff';

const evelethCleanRegular = {
  fontFamily: 'EvelethCleanRegular',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local('EvelethCleanRegular'),
    url(${EvelethCleanRegularWoff2}) format('woff2'),
    url(${EvelethCleanRegularWoff}) format('woff')
  `,
  fontDisplay: 'swap',
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff4400',
    },
    secondary: {
      main: '#0066ff',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    fontSize: 14,
    h1: {
      fontFamily: 'EvelethCleanRegular',
      fontSize: '2rem',
    },
    h2: {
      fontFamily: 'Helvetica',
      fontSize: '2rem',
    },
    h3: {
      fontFamily: 'EvelethCleanRegular',
      fontSize: '1.5rem',
    },
    h6: {
      fontFamily: 'EvelethCleanRegular',
      fontSize: '.9rem',
    },
    h5: {
      fontFamily: 'EvelethCleanRegular',
      fontSize: '.75rem',
    },
    h4: {
      fontFamily: 'EvelethCleanRegular',
      fontSize: '.3rem',
    },
    // Customize label text for checkboxes and form inputs
    body1: {
      fontFamily: 'Helvetica',
      fontSize: '1rem', // General body text size
      color: '#ededed', // Color for filters
    },
    // Customize buttons
    button: {
      fontFamily: 'EvelethCleanRegular',
      fontSize: '.25rem', // Font size for buttons like "View Drink"
      fontWeight: 'bold', // Bold text for buttons
    },
    // Customize dropdowns (Select input)
    subtitle1: {
      fontFamily: 'EvelethCleanRegular',
      fontSize: '.25rem', // Font size for dropdown items
    },
    // Customize the search box (input text inside TextField)
    input: {
      fontFamily: 'EvelethCleanRegular',
      fontSize: '.5rem', // Adjust this size for the search box text
    },
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontFamily: 'Helvetica',
          fontWeight: 'bold', // Bold text for labels
          fontSize: '1rem', // Checkbox label text
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'EvelethCleanRegular',
          fontSize: '.85rem', // Font size for buttons
          fontWeight: 'bold', // Bold font for buttons
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            fontFamily: 'Helvetica',
            fontWeight: 'bold', // Bold text for search input
            fontSize: '1rem', // Font size for search input text
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontFamily: 'EvelethCleanRegular',
          fontSize: '1rem', // Font size for dropdown text
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#bf0020', // Set a solid background color instead
          color: '#fff',  // Text color on the AppBar
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': [evelethCleanRegular],  // Include the font globally
        body: {
          backgroundColor: '#2b2b2b', // Apply background color to the whole app
          //backgroundImage: 'url(/textures/woodsml.jpg)', // Path to your background image or texture
          //backgroundSize: 'cover',   // Ensure it covers the AppBar area
          //backgroundPosition: 'center', // Position the background centrally
          //backgroundRepeat: 'no-repeat', // No repeat
          //margin: 0,
          //padding: 0,
          //height: '100%',
          //width: '100%',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#3a3a3a', // Dark background for Paper components
          color: '#ffffff', // Ensure text is readable
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontFamily: 'EvelethCleanRegular',  // Use the desired font family
          fontSize: '.5rem',  // Adjust the font size
          color: '#ffffff',  // Text color (unselected)
          '&.Mui-selected': {
            color: '#ff4400',  // Color when selected
            backgroundColor: '#3a3a3a',  // Adjust background when selected
          },
        },
      },
    },
  },
});
export default theme;
