// src/components/DrinkDetails.js
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  Container,
  Typography,
  CircularProgress,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  Button,
  Chip,
  Box
} from '@mui/material';

function DrinkDetails() {
  const { id } = useParams();
  const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000';  // Set dynamic API URL
  const [drink, setDrink] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${API_URL}/api/drinks/${id}/`, { credentials: 'include' })  // Use dynamic API URL
      .then(response => response.json())
      .then(data => {
        setDrink(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching drink details:', error);
        setLoading(false);
      });
  }, [id, API_URL]);

  if (loading) {
    return (
      <Container>
        <CircularProgress />
        <Typography variant="h6" align="center">Loading drink details...</Typography>
      </Container>
    );
  }

  if (!drink) {
    return (
      <Container>
        <Typography variant="h6" align="center">Drink not found</Typography>
      </Container>
    );
  }

  return (
    <Container>
      {/* Drink Details */}
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h3" gutterBottom>{drink.name}</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Type:</strong> {drink.type_of_drink}</Typography>
            <Typography variant="body1"><strong>Category:</strong> {drink.category}</Typography>
            <Typography variant="body1"><strong>Descriptor:</strong> {drink.descriptor}</Typography>
            <Typography variant="body1"><strong>Preparation Method:</strong> {drink.preparation_method}</Typography>
            <Typography variant="body1"><strong>Garnish:</strong> {drink.garnish ? drink.garnish.name : 'None'}</Typography>
            <Typography variant="body1"><strong>ABV:</strong> {drink.abv}%</Typography>
            <Typography variant="body1"><strong>Notes:</strong> {drink.notes || 'None'}</Typography>
            <Typography variant="body1"><strong>Process:</strong> {drink.process || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Ingredients</Typography>
            <List>
              {drink.ingredients.map((ingredient, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={`${ingredient.quantity || ''} ${ingredient.unit || ''} of ${ingredient.ingredient.name}${ingredient.ingredient.alcohol_by_volume ? ` - ABV: ${ingredient.ingredient.alcohol_by_volume}%` : ''}`}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Paper>


      {/* Adapted From Media */}
      {drink.media_adapted_from.length > 0 && (
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h5">Adapted From Media</Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {drink.media_adapted_from.map((media, index) => (
              <Chip key={index} label={media.title} variant="outlined" />
            ))}
          </Box>
        </Paper>
      )}

      {/* Tags */}
      {drink.tags.length > 0 && (
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h5">Tags</Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {drink.tags.map((tag, index) => (
              <Chip key={index} label={tag.name} variant="outlined" />
            ))}
          </Box>
        </Paper>
      )}

      {/* Episodes */}
      {drink.episodes.length > 0 && (
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h5">Episodes</Typography>
          <List>
            {drink.episodes.map((episode, index) => (
              <ListItem key={index}>
                <ListItemText 
                  primary={
                    <Link to={`/episodes/${episode.id}`}>
                      {episode.name} (Aired on {episode.episode_air_date})
                    </Link>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}

      {/* Back to Drinks link */}
      <Box sx={{ textAlign: 'center', mt: 3 }}>
        <Button variant="contained" color="primary" component={Link} to="/drinks">
          Back to Drinks
        </Button>
      </Box>
    </Container>
  );
}

export default DrinkDetails;
