// src/components/Header.js
import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

function Header() {
  const location = useLocation();

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h1" style={{ flexGrow: 1 }}>
          How to Drink
        </Typography>
        <Box>
          {location.pathname === '/drinks' && (
            <Button component={Link} to="/episodes" color="inherit">
              Episodes
            </Button>
          )}
          {location.pathname === '/episodes' && (
            <Button component={Link} to="/drinks" color="inherit">
              Drinks
            </Button>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
