import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './theme';  // Make sure this is the correct path to your theme.js
import Drinks from './components/Drinks';
import DrinkDetails from './components/DrinkDetails';
import Episodes from './components/Episodes';  
import EpisodeDetails from './components/EpisodeDetails';  
import HomePage from './components/HomePage';  // Import HomePage
import Header from './components/Header';  // Import the Header

function App() {
  return (
    <ThemeProvider theme={theme}>  {/* Wrap your app in ThemeProvider */}
      <CssBaseline />  {/* Ensures consistent base styling */}
      <Router>
        <Header />  {/* Place the Header component here */}
        <Routes>
          <Route path="/" element={<HomePage />} />  {/* Home Page Route */}
          <Route path="/drinks" element={<Drinks />} />
          <Route path="/drinks/:id" element={<DrinkDetails />} />
          <Route path="/episodes" element={<Episodes />} />
          <Route path="/episodes/:id" element={<EpisodeDetails />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
