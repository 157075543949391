import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, Typography, Card, CardContent, TextField, Checkbox, FormControlLabel, Button, CircularProgress, Box } from '@mui/material';

function Episodes() {
  const [episodes, setEpisodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [availableTags, setAvailableTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000';

  // Define allowed tags for episodes
  const predefinedEpisodeTags = ['cursed', 'syrups', 'drinks', 'review'];

  // Fetch episodes
  useEffect(() => {
    fetch(`${API_URL}/api/episodes/`, { credentials: 'include' })
      .then(response => response.json())
      .then(data => {
        setEpisodes(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching episodes:', error);
        setLoading(false);
      });
  }, [API_URL]);

  // Fetch tags
  useEffect(() => {
    fetch(`${API_URL}/api/tag-options/`, { credentials: 'include' })
      .then(response => response.json())
      .then(data => {
        const filteredTags = data.filter(tag => predefinedEpisodeTags.includes(tag));
        setAvailableTags(filteredTags);
      })
      .catch(error => console.error('Error fetching tags:', error));
  }, [API_URL]);

  const handleTagChange = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter(t => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const filteredEpisodes = episodes.filter(episode => {
    const matchesSearch = episode.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesTags = selectedTags.length === 0 || episode.tags.some(tag => selectedTags.includes(tag.name));
    return matchesSearch && matchesTags;
  });

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container>
      <Box my={3}>
        <Typography variant="h3" align="center" gutterBottom>
          Episodes
        </Typography>
      </Box>

      {/* Search Input */}
      <TextField
        label="Search episodes..."
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        margin="normal"
      />

      <Grid container spacing={3}>

        {/* Tags */}
        <Grid item xs={12}>
          <Typography variant="h6">Filter by Tags</Typography>
          {availableTags.map((tag, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={selectedTags.includes(tag)}
                  onChange={() => handleTagChange(tag)}
                />
              }
              label={tag}
            />
          ))}
        </Grid>
      </Grid>

      {/* Filtered Episodes List */}
        <Grid container spacing={3} style={{ marginTop: '20px' }}>
          {filteredEpisodes.length > 0 ? (
            filteredEpisodes.map((episode) => (
              <Grid item xs={6} sm={3} md={2} key={episode.id}>
                <Link to={`/episodes/${episode.id}`} style={{ textDecoration: 'none' }}>
                  <Card
                    style={{
                      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Initial drop shadow
                      borderRadius: '8px', // Rounded corners
                      transition: 'box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out', // Smooth transition
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.transform = 'scale(1.05)'; // Scale up on hover
                      e.currentTarget.style.boxShadow = '0px 8px 12px rgba(0, 0, 0, 0.2)'; // Deeper shadow on hover
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.transform = 'scale(1)'; // Reset scale
                      e.currentTarget.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)'; // Reset shadow
                    }}
                  >
                    <CardContent style={{ textAlign: 'center' }}>
                      <Typography variant="h5" align="center">
                        {episode.name}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
          ))
        ) : (
          <Typography variant="h6">No episodes found</Typography>
        )}
      </Grid>
    </Container>
  );
}

export default Episodes;
